import React, { useState, useEffect, useRef } from "react";
import Layout from "../layout/Layout";
import TextField from "../components/form/TextField";
import useGetResetPasswordOperation from "../hooks/resetpassword/useGetResetPassword";
import useToastify from "../hooks/ui/useToastify";
import * as Yup from "yup";
import { requiredEmailMsg, getFormError } from "../utils/form";
import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { isUserLoggedIn } from "../utils/auth";
import { Link, navigate } from "gatsby";
import { staticPage } from "../../config/staticPage";

const ForgotPasswordPage = () => {
  const user = isUserLoggedIn();

  const [localEmail, setLocalEmail] = useState("");
  const [showPopUp, setShowPopUp] = useState("");
  const [timer, setTimer] = useState(0);

  const { sendResetPasswordMail } = useGetResetPasswordOperation();
  const { toastMessage } = useToastify();

  const initial_values = {
    email: "",
  };
  const recaptchaRef = useRef();

  const validation_schema = Yup.object().shape({
    email: Yup.string()
      .email(requiredEmailMsg())
      .required(requiredEmailMsg("Email Address")),
  });

  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validation_schema,
    onSubmit: async (values) => {
      setLocalEmail(values.email);
      const recaptchaValue = recaptchaRef.current.getValue();
      if (recaptchaValue) {
        const res = await sendResetPasswordMail(values.email);
        if (res) {
          setShowPopUp(true);
          startTimer();
        } else {
          setShowPopUp(false);
        }
      } else {
        toastMessage("error", "Please Check re-captcha to send mail");
        // Display an error message or take appropriate action
      }
    },
  });

  const startTimer = () => {
    setTimer(60);
  };

  const onClickResendEmail = async () => {
    const res = await sendResetPasswordMail(localEmail);
    if (res) {
      setShowPopUp(true);
      startTimer();
    } else {
      setShowPopUp(false);
    }
    startTimer();
  };

  useEffect(() => {
    let interval;
    if (showPopUp) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showPopUp]);

  useEffect(() => {
    if (user) {
      navigate("/orders");
    }
  }, [user]);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-heading-section">
          <div className="container">
            <h1 className="page-title">Reset Password</h1>
          </div>
        </div>
      </div>

      <div className="container">
        {!showPopUp && (
          <>
            <p className="text-center">
              Fill in your email below to request a new password. An email will
              be sent to the address below containing a link to verify your
              email address.
            </p>

            <form
              className="form forgot-password-form form-mid"
              onSubmit={formik.handleSubmit}
            >
              <div className="">
                <TextField
                  id={"email"}
                  name={"email"}
                  label={"Email Address"}
                  labelFor={"email"}
                  type={"input"}
                  onChange={formik.handleChange}
                  touched={formik.touched["email"]}
                  error={getFormError(formik.errors, "email")}
                  required
                />
                <div style={{ margin: "15px 0" }}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}
                  />
                </div>
                <button type="submit" class="button">
                  Reset Password
                </button>

              </div>
            </form>


            <div className="text-center">
              <br/><br/><br/>
              Already have an account?&nbsp;&nbsp;
              <Link to="/login" className="link-style">Login Here</Link>
              <br/><br/><br/>
            </div>

          </>
        )}
        {showPopUp && (
          <div className="Success-div" style={{ textAlign: "center" }}>
            <h1>Password reset email sent</h1>
            <p style={{ fontSize: "20px" }}>
              A link to reset your password has been sent to{" "}
              <b>{localEmail ? localEmail.toLowerCase() : ""}</b> <br></br>{" "}
              Didn't get the email? Click below
            </p>
            {timer > 0 ? (
              <h4>
                You can resend the password reset email in{" "}
                <span style={{ color: "red" }}>{timer}s</span>
              </h4>
            ) : (
              <button className="button" onClick={onClickResendEmail}>
                Resend Email
              </button>
            )}
            <Link to="/login">
              Login
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "forget-password") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

ForgotPasswordPage.Layout = Layout
export default ForgotPasswordPage;
